import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import Container from 'molecules/Container';
import WithBGImage from 'molecules/WithBGImage';
import './HeroHome.scss';

/**
 * Hero Home.
 */
const propTypes = {
  data: PropTypes.object,
}
const HeroHome = p => {

  const className = classnames(
    'HeroHome',
    p.className
  );

  return(

    <WithBGImage
    className={ className }
    image={ {
      data: p.data.bg
    } }
    overlay={ true }
    >
      <Container
      className="HeroHome__content"
      size="sm"
      >
        <h1 className="HeroHome__content-hline text-center">
          { p.data.fields.prehline &&
            <span className="pre d-block">{ p.data.fields.prehline }</span>
          }
          <span className="main d-block">{ p.data.fields.hline }</span>
        </h1>
      </Container>

    </WithBGImage>

  )

};
HeroHome.propTypes = propTypes;

export default HeroHome;
