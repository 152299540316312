import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import Button from 'molecules/Button';
import Container from 'molecules/Container';
import HTML from 'molecules/HTML';
import Link from 'molecules/Link';
import WithBGImage from 'molecules/WithBGImage';
import redmillsBG from 'static/images/redmills-building-gray.jpg';
import './HighlightsHome.scss';

/**
 * Latest Sermon.
 */
const propTypes = {
  data: PropTypes.object,
}
const HighlightsHome = p => {

  /* ------ = Output. = --------------------------------------------------------------------- */

  const className = classnames(
    'Hlights',
    p.className
  );

  return(

    <WithBGImage
    className={ className }
    image={ {
      src: redmillsBG
    } }
    >

      <Container>

        { /* Links */}
        { !! p.data.links &&

          <div className="Hlights__boxes row align-items-center">

            { p.data.links.map( link => (

              <div className="Hlights__box col-sm-4">
                <Link to={ link.page.slug.current }>
                  <WithBGImage
                  className="Hlights__box-bg"
                  image={ {
                    data: link.bg
                  } }
                  overlay={ true }
                  >
                    <h3 className="Hlights__box-hding">
                      { link.text }
                    </h3>

                  </WithBGImage>
                </Link>

            </div>

            ) ) }

          </div>


        }

        { /* Meeting times */ }
        <Container
        className="Hlights__meetings"
        size="md"
        >
          <div className="row align-items-center">
            <div className="col-md-8">
              <HTML tag="p">{ p.data.fields.text }</HTML>
            </div>
            <div className="col-md-4">
              <Button
              className="Hlights__meetings-cta"
              color="red"
              link={ {
                to: '/contact',
              } }
              >
                How to find us
              </Button>
            </div>
          </div>


        </Container>


      </Container>

    </WithBGImage>

  )

};

export default HighlightsHome;
