import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

//import Button from 'molecules/Button';
import Container from 'molecules/Container';
import './BottomHome.scss';

/**
 * Bottom Home.
 */
const propTypes = {
  data: PropTypes.object,
}
const BottomHome = ( { data } ) => {

  return(

    <div className="BottomHome">
      <Container size="sm">

        <p className="BottomHome__text">
          { data.text }
        </p>
        <div className="BottomHome__ref">
          { data.reference }
        </div>

      </Container>
    </div>

  );

};

export default BottomHome;