import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import BottomHome from 'organisms/BottomHome';
import HeroHome from 'organisms/HeroHome';
import HighlightsHome from 'organisms/HighlightsHome';
import IntroHome from 'organisms/IntroHome';
import LatestSermon from 'organisms/LatestSermon';
import UpdatesHome from 'organisms/UpdatesHome';



/**
 * Home Template.
 */
const Home = ( { pageContext: page } ) => {

  const { content } = page;


  // /* ------ = Output. = --------------------------------------------------------------------- */
  const className = classnames(
    'Home',
  );

  return(

    <div className={ className }>

      <section>
        <HeroHome data={ content.hero } />
      </section>

      <section>
        <LatestSermon data={ content.latest } />
      </section>

      <section>
        <IntroHome data={ content.intro } />
      </section>

      { !! content.highlights &&
        <section>
          <HighlightsHome data={ content.highlights } />
        </section>
      }


      <section>
        <UpdatesHome />
      </section>

      <section>
        <BottomHome data={ content.bottom } />
      </section>


    </div>

  )

};
export default Home;

// export const query = graphql`
//   query Query( $id: String! ) {
//     page: sanityPage( id: { eq: $id } ) {
//       content: _rawContent( resolveReferences: { maxDepth: 10 } )
//     }
//   }
// `;
