import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { FaRegPlayCircle } from 'react-icons/fa';


import AppContext from 'AppContext';
import Button from 'molecules/Button';
import Container from 'molecules/Container';
import recentSermons from 'data/globals/recent-sermons';
import './LatestSermon.scss';

/**
 * Latest Sermon.
 */
const propTypes = {
  data: PropTypes.object,
}
const Latest = p => {

  console.log( '=======', recentSermons );

  const AppState = React.useContext( AppContext );
  const sermon = recentSermons[0];

  console.log( 'SERMON', sermon );

  React.useEffect( () => {

  }, [] );
  /* ------ = Output. = --------------------------------------------------------------------- */

  const className = classnames(
    'Latest',
    p.className
  );

  return(

    <div className={ className }>

      <Container>
        <div className="row align-items-center">

          <div className="col-sm-3">
            <h2 className="Latest__hding f-caps">Latest Sermon <br />{ format( Date.parse( sermon.date ), 'MMMM d, yyyy' ) }</h2>
          </div>

          <div className="col-sm-6">
            <h3 className="Latest__title ">{ sermon.title }</h3>
            <div className="Latest__meta">
              <span className="Latest__meta-name">{ sermon.speaker }</span>
              <span className="Latest__meta-bible">{ sermon.bibletext }</span>
            </div>
          </div>

          <div className="col-sm-3">
            <Button
            className="Latest__listen"
            color="gold-dark"
            icon={ <FaRegPlayCircle /> }
            size="lg"
            onClick={ () => AppState.setState( { sermonPlayer: { isShowing: ! AppState.sermonPlayer.isShowing } } ) }
            >
              Listen
            </Button>

          </div>

        </div>

      </Container>

    </div>

  )

};

export default Latest;
