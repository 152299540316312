import classnames from 'classnames';
import to from 'await-to-js';
import React from 'react';
import PropTypes from 'prop-types';
import { useSetState } from 'react-use';
import { graphql, useStaticQuery } from 'gatsby';

//import Button from 'molecules/Button';
import BlockContent from 'molecules/BlockContent';
import Container from 'molecules/Container';
import HTML from 'molecules/HTML';
import Link from 'molecules/Link';
import { client } from 'lib/sanity';

import recentPosts from 'data/globals/recent-posts';
import events from 'data/globals/events';

import './UpdatesHome.scss';

recentPosts.splice( 0, 3 );

/**
 * Updates Home Sermon.
 * @note we can now import recent posts directly.
 */
const propTypes = {
  data: PropTypes.object,
}
const UpdatesHome = p => {

  /* ------ = Lifecycle. = --------------------------------------------------------------------- */

  return(

    <div className="UpdatesHome">

      <Container>

        <div className="row">

          { /* Blog */ }

          <div className="col-md-6">
            <div className="UpdatesHome__box is-blog">
              <h2 className="UpdatesHome__box-hding">Pastor's Blog</h2>
              { !! recentPosts?.length &&

                <ul className="cleanlist">
                  { recentPosts.map( post => {

                    const legacySummary = !! post.legacySummary ? post.legacySummary.replace( '[…]', '...' ) : null;

                    return(

                      <li
                      key={ post.id }
                      className="UpdatesHome__post"
                      >
                        <h3 className="UpdatesHome__post-title f-hding-2">
                          <Link to={ `/blog/${post.slug.current}` }>
                            { post.title }
                          </Link>
                        </h3>
                        <div className="UpdatesHome__post-summary">

                          { !! post.summary

                            ?
                              <div className="legacy">
                                <p>
                                  { post.summary }
                                </p>
                              </div>

                            :
                              <HTML className="legacy">
                                {  legacySummary }
                              </HTML>
                          }
                          <Link
                          className="readmore"
                          to={ `/blog/${post.slug.current}` }
                          >
                            Read More
                          </Link>

                        </div>
                      </li>

                    )

                  } ) }
                </ul>
              }
            </div>
          </div>

          { /* Events */ }
          <div className="col-md-6">
            <div className="UpdatesHome__box is-events">
              <h2
              className="UpdatesHome__box-hding"
              >Events & Announcements</h2>
              { !! events?.length &&
                <ul className="cleanlist">

                  { events.map( ( event, key ) => (

                    <li
                    key={ key }
                    className="UpdatesHome__event"
                    >
                      <h3 className="UpdatesHome__post-title f-hding-3">
                        { event.title }
                      </h3>
                    </li>

                  ) ) }
                </ul>
              }
            </div>
          </div>

        </div>

      </Container>

    </div>

  )

};

export default UpdatesHome;
