import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { FaRegPlayCircle } from 'react-icons/fa';

import Button from 'molecules/Button';
import Container from 'molecules/Container';
import './IntroHome.scss';

/**
 * IntroHome Sermon.
 */
const propTypes = {
  data: PropTypes.object,
}
const IntroHome = p => {


  return(

    <div className="IntroHome">

      <Container size="sm">

        { p.data.hding &&
          <h2 className="IntroHome__hding">{ p.data.hding }</h2>
        }
        <p className="IntroHome__msg">{ p.data.message }</p>

      </Container>

    </div>

  )

};

export default IntroHome;
